import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { Flex } from "@rebass/grid";
import { Helmet } from "react-helmet";
import CI from "@trackcode/ci";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Lato";
    margin: 0;
    padding: 0;
  }
`;

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${CI.color.grayLight};
  @media print {
    position: static;
  }
`;

const Content = styled(Flex)`
  overflow: auto;
`;

const theme = {
  breakpoints: ["40em", "52em", "64em"],
  colors: {},
  space: [0, 4, 8, 16, 32, 64, 128, 256]
};

const Layout = ({ appBar, children }) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <title>TrackCode</title>
    </Helmet>
    <GlobalStyle />
    <FullPage>
      {appBar}
      <Content flex={1} flexDirection="row">
        {children}
      </Content>
    </FullPage>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
