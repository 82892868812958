import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";
import TrackCodeLogoDark from "../images/trackcode-logo-dark.png";

export default () => (
  <Wrapper>
    <AppBar>
      <Flex flex={1} alignItems="center">
        <Logo src={TrackCodeLogoDark} />
      </Flex>
    </AppBar>
  </Wrapper>
);

const AppBar = styled(Flex)`
  min-height: 50px;
  background-image: linear-gradient(#ffffff, #f8f8f8);
  box-shadow: 0 1px 4px 0 rgba(44, 44, 44, 0.4);
  align-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  @media print {
    display: none;
  }
`;

const Logo = styled.img`
  height: 36px;
  padding-left: 6px;
`;
